import { FlagIcon, UserContainer, UserInfo, UserPicture, UserTestimonyItemWrapper } from './styles';
import HtmlParserContent from '../HTMLParsedContent';
import { useRouter } from 'next/router';
import { UploadFile } from '../../../types/schema';
import { formatSrcSet } from '../../../utils/formatURLs';

/*
export type TestimonyType = "client" | "founder" | "employee" | "student";

 * UserTestimonyItem
 */
export type UserTestimonyItemProps = {
  compact?: boolean;
  name?: string;
  type?: string;
  occupation?: string;
  companyName?: string;
  image?: UploadFile;
  featured?: string;
  aboutFeatured?: string;
  testimony?: string;
  clientCountry?: string;
  cardBackground?: 'white' | 'darkGradient' | 'lightGradient' | 'darkBlue';
};

const UserTestimonyItem: React.FC<UserTestimonyItemProps> = ({
  compact,
  name,
  type,
  occupation,
  companyName,
  image,
  testimony,
  clientCountry,
  cardBackground,
}) => {
  const router = useRouter();
  const isEn = router.locale === 'en';
  return (
    <UserTestimonyItemWrapper
      $compact={compact as boolean}
      $cardBackground={cardBackground || 'white'}
    >
      <HtmlParserContent content={testimony as string} />
      <UserContainer>
        {image && (
          <UserPicture
            className="img"
            srcSet={formatSrcSet(image, 'userTestimony')}
            loading="lazy"
            alt={isEn ? `${name}'s photo` : `Foto do ${name}`}
          />
        )}
        <UserInfo>
          <div>
            <h3>{name}</h3>
            {clientCountry && (
              <FlagIcon>
                {clientCountry === 'EUA' && (
                  <img src="/img/united-states-of-america.png" alt="usa flag" />
                )}
                {clientCountry === 'Canada' && <img src="/img/canada.png" alt="canada flag" />}
              </FlagIcon>
            )}
          </div>
          {!!(companyName || occupation) && (
            <p>
              {`${
                occupation
                  ? `${
                      type === 'employee' && !!companyName
                        ? isEn
                          ? 'Currently'
                          : 'Atualmente'
                        : ''
                    } ` +
                    occupation +
                    `${companyName ? ` ${isEn ? 'at' : 'na'} ${companyName}` : ''}`
                  : companyName || ''
              }`}
            </p>
          )}
        </UserInfo>
      </UserContainer>
    </UserTestimonyItemWrapper>
  );
};
export default UserTestimonyItem;
