import Link from 'next/link';
import { FC } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useMedia } from 'react-use';
import { Enum_Componentcomponentssectioncards_Cardsposition as CardsPosition } from '../../../types/schema';
import HTMLParsedContent from '../HTMLParsedContent';
import * as Styled from './styles';

type Card = {
  title?: string;
  content?: string;
  showNumber?: boolean;
  icon?: string;
};

type CallToAction = {
  title: string;
  destinationUrl: string;
};

export type SectionCardsProps = {
  cardsPosition?: CardsPosition;
  content?: string;
  cards: Card[];
  callToAction?: CallToAction;
  backgroundColor?: 'blue' | 'darkBlue';
};

export const SectionCards: FC<SectionCardsProps> = ({
  callToAction,
  cards = [],
  cardsPosition = 'inline' as CardsPosition,
  content,
  backgroundColor,
}) => {
  const isMobile = useMedia('(max-width: 768px)', true);

  return (
    <Styled.Container
      cardsCount={cards.length}
      backgroundColor={backgroundColor}
      cardsPosition={cardsPosition}
    >
      {content ? (
        <div className="text-content">
          <HTMLParsedContent content={content} />
          {callToAction && cardsPosition !== 'inline' && !isMobile ? (
            <Link href={callToAction.destinationUrl} passHref>
              {callToAction.title}
              <BsArrowRight size={22} />
            </Link>
          ) : null}
        </div>
      ) : null}

      <ol className="cards-container">
        {cards.map((card, index) => (
          <li key={index}>
            {card.icon ? (
              <img className="icon" src={card.icon} alt="" width={24} height={24} />
            ) : null}
            {card.title ? (
              <h3>
                {card.showNumber ? <span className="number">0{index + 1}</span> : null}
                {card.title}
              </h3>
            ) : null}
            {card.title && card.content ? <hr /> : null}
            {card.content ? (
              <div className="content">
                <HTMLParsedContent content={card.content} />
              </div>
            ) : null}
          </li>
        ))}
      </ol>

      {callToAction && (cardsPosition === 'inline' || isMobile) ? (
        <Link href={callToAction.destinationUrl} passHref>
          {callToAction.title}
          <BsArrowRight size={22} />
        </Link>
      ) : null}
    </Styled.Container>
  );
};
