import Script from 'next/script';
import React from 'react';

interface IProps {
  slug: string;
}

const HubspotMeeting: React.FC<IProps> = ({ slug }) => {
  return (
    <div>
      <Script
        type="text/javascript"
        src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
      />
      <div
        className="meetings-iframe-container"
        data-src={`https://meetings.hubspot.com/${slug}?embed=true`}
      ></div>
    </div>
  );
};

export default HubspotMeeting;
