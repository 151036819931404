import Link from 'next/link';
import React from 'react';
import { PostWrapper } from './styles';
import { Blog, UploadFile } from '../../../types/schema';
import dayjs from 'dayjs';
import { formatSrcSet } from '../../../utils/formatURLs';
import Typography from '../Typography';
import ClientComponent from '../ClientComponent/ClientComponent';

export type PostItemProps = {
  background?: 'primary' | 'white' | 'transparent';
  post: Blog;
  formatedfeaturedImage?: string;
  related?: boolean;
  isEn: boolean;
};

const PostItem: React.FC<PostItemProps> = ({ post, related, isEn }, i) => {
  const { featuredImage, title, author, description, locale, slug, publicationDate } = post;
  return (
    <PostWrapper key={i} related={related}>
      {featuredImage && (
        <section>
          <Link href={`/${locale}/blog/${slug}/`} passHref>
            <img
              srcSet={formatSrcSet(
                post.featuredImage?.data?.attributes as UploadFile,
                related ? '' : 'postThumbnail',
                related
                  ? {
                      resize: {
                        width: '600',
                        height: '150',
                        type: 'fit',
                      },
                      quality: 70,
                    }
                  : undefined,
              )}
              alt={title || ''}
              loading="lazy"
            />{' '}
          </Link>
        </section>
      )}
      <div>
        <Link
          href={`/${locale}/blog/${slug}/`}
          tabIndex={0}
          title={isEn ? 'Go to post page.' : 'Ir para a página do post.'}
        >
          <h2>{title}</h2>
          <span tabIndex={-1}>
            {author?.data?.attributes?.name ? `${author?.data?.attributes?.name} - ` : ''}
            <ClientComponent>
              {isEn
                ? dayjs(publicationDate).locale('en').format('DD MMM YYYY')
                : dayjs(publicationDate).locale('pt-br').format('DD MMM YYYY')}
            </ClientComponent>
          </span>
        </Link>

        <Typography variant="p">{(description || '').replace(/<[^>]*>?/gm, '')}</Typography>
      </div>
    </PostWrapper>
  );
};

export default PostItem;
