import Link from 'next/link';
import React from 'react';
import { ButtonVariant } from '../../../styles/Button';
import Button from '../Button';
import HTMLParsedContent from '../HTMLParsedContent';
import { ButtonsWrapper, CallToActionWrapper, Content } from './styles';

export type SectionTextButtonsProps = {
  text: string;
  buttons?: buttonProps[];
  buttonsAlignment: 'left' | 'center' | 'right';
  backgroundColor?: string;
};

type buttonProps = {
  title: string;
  action: string;
  type: string;
};

const SectionTextButtons: React.FC<SectionTextButtonsProps> = ({
  text,
  buttons,
  buttonsAlignment,
  backgroundColor,
}) => {
  return (
    <CallToActionWrapper>
      <Content backgroundColor={backgroundColor}>
        <HTMLParsedContent content={text} />
        {buttons && buttons.length > 0 && (
          <ButtonsWrapper buttonsAlignment={buttonsAlignment}>
            <div>
              {buttons.map((button: buttonProps, index) => {
                const shouldScroll = button.action && button.action[0] === '#';
                if (shouldScroll) {
                  return (
                    <Button
                      key={index}
                      variant={button.type as ButtonVariant}
                      backgroundColor={backgroundColor}
                      onClick={() => {
                        const section = document.querySelector(button.action);
                        section?.scrollIntoView({
                          behavior: 'smooth',
                          block: 'center',
                        });
                      }}
                    >
                      {button.title}
                    </Button>
                  );
                }

                return button.action ? (
                  <Link href={button.action} key={index}>
                    <Button
                      variant={button.type as ButtonVariant}
                      backgroundColor={backgroundColor}
                    >
                      {button.title}
                    </Button>
                  </Link>
                ) : (
                  <Button
                    key={index}
                    variant={button.type as ButtonVariant}
                    backgroundColor={backgroundColor}
                  >
                    {button.title}
                  </Button>
                );
              })}
            </div>
          </ButtonsWrapper>
        )}
      </Content>
    </CallToActionWrapper>
  );
};

export default SectionTextButtons;
