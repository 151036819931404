import React from 'react';
import { RiStackshareLine } from 'react-icons/ri';
import { useRouter } from 'next/router';
import { ContainerStackshare } from './styles';

export type StackshareProps = {
  url?: string;
};

const Stackshare: React.FC<StackshareProps> = ({ url }) => {
  const router = useRouter();
  const isEn = router.locale === 'en';
  if (url) {
    return (
      <ContainerStackshare>
        <a href={url as string} target="_blank" rel="noreferrer">
          <div className="logo-container">
            <RiStackshareLine className="logo" />
          </div>
          <span>{isEn ? 'View on Stackshare' : 'Visualizar no Stackshare'}</span>
        </a>
      </ContainerStackshare>
    );
  } else {
    return null;
  }
};

export default Stackshare;
