import { FC } from 'react';
import type {
  ComponentComponentsSectionImageRichTextList,
  Enum_Componentcomponentssectionimagerichtextlist_Mediatype as MediaType,
} from '../../../types/schema';
import HTMLParsedContent from '../HTMLParsedContent';
import * as Styled from './styles';

const SectionImageRichTextList: FC<ComponentComponentsSectionImageRichTextList> = ({
  SectionImageRichTextListItems,
  mediaType,
}) => {
  return (
    <Styled.Container>
      {SectionImageRichTextListItems.map((item, key) => {
        const imgUrl = item?.image?.data?.attributes?.url;

        return (
          <Styled.Item
            key={key}
            mediaType={mediaType ?? ('icon' as MediaType)}
            withImage={!!imgUrl}
          >
            <div className="img-container">
              <img alt="" aria-hidden src={imgUrl || '/icons/nebulas-blue.svg'} loading="lazy" />
            </div>
            {item?.text && <HTMLParsedContent content={item.text} />}
          </Styled.Item>
        );
      })}
    </Styled.Container>
  );
};

export default SectionImageRichTextList;
