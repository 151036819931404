import React from 'react';

import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import ModalImageTemplate from './template';

type ModalImageProps = {
  image: string;
  isOpen: boolean;
  handleIsOpen: (index: number) => void;
};

const ModalImage: React.FC<ModalImageProps> = ({ image, isOpen, handleIsOpen }) => {
  return <>{isOpen && <ModalImageTemplate mainSrc={image} handleIsOpen={handleIsOpen} />}</>;
};

export default ModalImage;
