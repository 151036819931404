import styled from 'styled-components';
import media from '../../../styles/media';

export const GalleryWrapper = styled.div<{
  columns: number;
  size: 'small' | 'medium' | 'large';
}>`
  display: grid;
  margin: 64px auto;
  gap: 32px;
  grid-template-columns: 1fr;
  max-width: 1200px;

  ${media('small')} {
    grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  }

  img:hover {
    cursor: pointer;
  }

  .img {
    margin: auto;
    width: ${(props) =>
      props.size === 'large' ? '100%' : props.size === 'medium' ? '70%' : '40%'};
    /* max-height: 400px; */
    transition: all 200ms ease-in 0s;
    &:hover {
      img {
        filter: drop-shadow(0px 0px 10px rgb(90, 90, 90, 0.5));
      }
      transform: scale(1.01);
    }
    .border-radius {
      border-radius: 5px;
    }

    ${media('medium', true)} {
      width: 100%;
    }
  }
  //Corta a imagem pra caber no container pai
  .covered {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  //Mantem as proporções originais da imagem
  .contained {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;
