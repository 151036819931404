import styled from 'styled-components';
import media from '../../../styles/media';

export const ComponentBackground = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 0; // DynamicZoneContent already have some padding
`;

export const ListWrapper = styled.div<{ itemCount: number }>`
  max-width: 1200px;

  // Grid
  display: grid;
  gap: 50px;
  grid-template-columns: 1fr;
  ${media('small')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${media('mediumPlus')} {
    // Don't allow more than 4 columns
    grid-template-columns: repeat(${(props) => Math.min(props.itemCount, 4)}, 1fr);
  }
`;

export const ListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 20fr;
  gap: 30px;
`;

export const ItemContent = styled.div`
  box-sizing: border-box;
  // Manually setting styles for the RichText
  * {
    color: white;
    margin-top: 0px !important; // Margin was being automatically added, making it hard to align
  }
  p {
    font-weight: 400;
  }
  h2 {
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
  a {
    cursor: pointer;
  }
`;
export const ItemIcon = styled.div`
  color: white;

  // Handling custom icon image
  img {
    height: 20px;
    width: 20px;
    filter: brightness(0) invert(1); // Forcing icon to be white
  }

  // Center icon
  display: flex;
  align-items: center;
  justify-content: center;

  // Light background
  background-color: rgb(255, 255, 255, 0.05);
  height: 50px;
  width: 50px;
  border-radius: 50px;
`;
export const ItemNavigation = styled.div`
  color: white;
  a {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
