import styled, { css } from 'styled-components';
import media from '../../../styles/media';
import { Enum_Componentcomponentssectionimagerichtextlist_Mediatype as MediaType } from '../../../types/schema';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
`;

export const Item = styled.div<{ mediaType?: MediaType; withImage?: boolean }>`
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 40px 0;

  :nth-child(even) {
    flex-direction: row-reverse;
  }

  :not(:last-child) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.newBorder}80;
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    overflow: hidden;

    ${({ mediaType, withImage }) =>
      mediaType === 'image'
        ? css`
            border-radius: ${withImage ? '8px' : '50%'};
            max-width: 300px;
            max-height: 300px;

            ${!withImage &&
            css`
              min-width: 150px;
              min-height: 150px;
              background-color: ${({ theme }) => theme.colors.lightCardBlue};
            `}
          `
        : css`
            border-radius: 50%;
            min-width: 150px;
            width: 150px;
            height: 150px;
            background-color: ${({ theme }) => theme.colors.lightCardBlue};
          `}

    img {
      ${({ withImage, mediaType }) =>
        mediaType === 'image' && withImage
          ? css`
              max-width: 100%;
              max-height: 100%;
            `
          : css`
              max-width: 40%;
              max-height: 40%;
              min-width: 40%;
              min-height: 40%;
            `}
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${({ theme }) => theme.colors.darkText};
  }

  ${media('medium', true)} {
    ${({ mediaType }) =>
      mediaType === 'image' &&
      css`
        flex-direction: column;
        :nth-child(even) {
          flex-direction: column;
        }

        .img-container {
          max-height: 400px;
          max-width: 400px;
        }
      `}
  }

  ${media('small', true)} {
    ${({ mediaType }) =>
      mediaType === 'icon' &&
      css`
        flex-direction: column;
        :nth-child(even) {
          flex-direction: column;
        }
      `}
  }
`;
