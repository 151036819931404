import styled, { css } from 'styled-components';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
  gap: 1rem;
`;

export const SectionContainer = styled.div<{ scale: number }>`
  ${({ scale, theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${scale * 1}rem;
    width: 100%;

    h3 {
      margin: 0;
      font-size: ${scale * 2}rem;
      font-weight: 600;
      color: ${theme.colors.darkBlue};
    }

    div.events {
      display: flex;
      flex-direction: column;
      gap: ${scale * 1}rem;
    }
  `}
`;

export const EventItem = styled.div<{
  scale: number;
  expanded: boolean;
  isLocationLarger: boolean;
}>`
  ${({ scale, theme, expanded, isLocationLarger }) => css`
    border: 2px solid #e5e5e5;
    border-radius: 16px;
    display: flex;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    max-height: ${expanded ? 1000 : 350 * scale}px;

    div.img-container {
      display: flex;
      overflow: hidden;
      width: 100%;
      max-width: ${scale * 350}px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        min-height: 200px;
      }
    }

    div.info-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 16px;
      width: 100%;

      div.header {
        display: flex;
      }

      div.location-container {
        display: flex;
        flex-direction: column;
        height: 100%;

        h3.title {
          font-size: ${scale * 1.5}rem;
          font-weight: 600;
          margin: 0;
          color: ${theme.colors.darkBlue};
        }

        p.location {
          margin: 0;
          display: flex;
          flex-direction: column;
          gap: 0.25rem;
          color: ${theme.colors.darkBlue};
          margin-top: -10px;

          span {
            font-size: ${scale * 1}rem;
            font-weight: 500;
            opacity: 0.8;
            color: inherit;
            &.city {
              opacity: 1;
              font-weight: 700;
            }
          }
        }
      }

      span.date {
        color: ${theme.colors.darkBlue};
        font-size: ${scale * 1.5}rem;
        font-weight: 600;
        opacity: 0.8;
        margin-left: auto;
      }

      .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${expanded
          ? 9999
          : scale === 1
            ? isLocationLarger
              ? 4
              : 6
            : isLocationLarger
              ? 3
              : 4};
        overflow: hidden;
      }

      .detail-page-message {
        display: block;
        font-weight: 600;
        font-size: 18px;
        margin-top: 10px;
      }

      a {
        color: ${theme.colors.darkBlue};
        font-size: ${scale * 0.9}rem;
        font-weight: 500;
        border-bottom: 1px solid ${theme.colors.darkBlue}80;
        padding-bottom: 4px;
        width: fit-content;

        svg {
          margin-left: 4px;
        }
      }

      div.btn-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        button {
          padding: 4px 8px;
          cursor: pointer;
          border: none;
          background: none;
          color: ${theme.colors.darkBlue};
          opacity: 0.6;
          font-size: ${scale * 0.9}rem;
          display: flex;
          align-items: center;
          svg {
            color: ${theme.colors.darkBlue};
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;
      max-height: unset;

      div.img-container {
        max-width: 100%;
        height: 300px;
      }

      div.info-container {
        div.header {
          flex-direction: column;
        }
        span.date {
          margin-left: 0;
          margin-top: -0.5rem;
          font-size: ${scale * 1.2}rem;
        }
      }
    }
  `}
`;
