import styled from 'styled-components';
import media from '../../../styles/media';

export const Container = styled.div<{ backgroundColor: string }>`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

  > div > h2 {
    span {
      color: ${(props) =>
        ['darkBlue', 'darkGradient'].includes(props.backgroundColor)
          ? props.theme.colors.white
          : props.theme.colors.darkText} !important;
    }

    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 45px;
    font-weight: 500;
    line-height: 1.1;
  }
  > div > h3 {
    font-weight: 400;
    font-size: 22px;
    text-align: center;
    span {
      color: ${(props) =>
        ['darkBlue', 'darkGradient'].includes(props.backgroundColor)
          ? props.theme.colors.white
          : props.theme.colors.darkText};
    }
  }
`;

export const UserTestimonyWrapper = styled.div<{ columns: number }>`
  display: grid;
  padding: 0;

  grid-template-columns: 1fr;
  ${media('mediumPlus')} {
    // Don't allow more than 3 columns
    grid-template-columns: repeat(${(props) => Math.min(props.columns, 3)}, 1fr);
    padding: 0;
  }
  gap: 10px;
`;
