import styled from 'styled-components';

export const HTMLParsedContentWrapper = styled.div`
  blockquote {
    margin: 0px;
    background: rgba(0, 0, 0, 0.03) none repeat scroll 0% 0%;
    padding: 24px 16px;
    border-left: 2px solid ${(props) => props.theme.colors.blue};
    font-weight: 400;
    color: ${(props) => props.theme.colors.darkText};
    transition: border-left-width 200ms ease-in 0s;
    p {
      color: ${(props) => props.theme.colors.darkText};
    }
  }
  blockquote:hover {
    border-left-width: 4px;
  }
  a {
    color: ${(props) => props.theme.colors.blue};
    font-weight: 500;
  }
  a:focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.blue};
    border-radius: 3px;
  }
  blockquote p {
    margin-bottom: 0;
  }
  p {
    color: ${(props) => props.theme.colors.darkText};
  }
  li {
    margin-right: 30px;
    text-align: left;
  }

  code {
    font-family: Monaco, monospace;
    line-height: 100%;
    background-color: #e9e9e9;
    padding: 0.2em;
    letter-spacing: -0.05em;
    word-break: normal;
    border-radius: 4px;
  }

  &&& {
    strong {
      color: ${(props) => props.theme.colors.boldText};
    }
  }
`;
