import React from 'react';
import { ContentWrapper, QuoteImage, SectionQuoteWrapper, TextWrapper } from './styles';
import Image from 'next/image';
import HTMLParsedContent from '../HTMLParsedContent';

interface IProps {
  text: string;
}

const SectionQuote: React.FC<IProps> = ({ text }) => {
  return (
    <SectionQuoteWrapper>
      <ContentWrapper>
        <QuoteImage>
          <Image
            className="quote-image"
            src="/img/quote-mark-white.svg"
            alt="Quote image"
            layout="responsive"
            height={100}
            width={100}
            style={{ objectFit: 'cover' }}
          />
        </QuoteImage>
        <TextWrapper>
          <HTMLParsedContent content={text} />
        </TextWrapper>
      </ContentWrapper>
    </SectionQuoteWrapper>
  );
};

export default SectionQuote;
