import styled, { css } from 'styled-components';
import { SectionBackgroundImageRichTextProps } from '.';
import media from '../../../styles/media';

export const Wrapper = styled.div<{
  textPosition: SectionBackgroundImageRichTextProps['textPosition'];
}>`
  ${({ textPosition }) => css`
    display: grid;
    grid-template-columns: ${textPosition === 'center' ? '0.25fr 0.5fr 0.25fr' : '1fr 1fr'};
    max-width: 1200px;
    margin: auto;

    & > div {
      grid-column: ${textPosition === 'left' ? 1 : 2};
    }

    ${media('small', true)} {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`;
