import styled, { css } from 'styled-components';
import { Container } from '../../../styles/Container';
import media from '../../../styles/media';

export const BannerPrimary = styled.div<{
  bgImage?: string;
  size?: 'full' | number;
  $type?: string;
  $small?: boolean;
  $layer: boolean;
}>`
  margin-bottom: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0px 2px 6px #00000020;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${(props) => (props.size === 'full' ? '100vh' : `470px`)};

    ${(props) =>
      props.bgImage
        ? css`
            // Use image if available, if not, use gradient
            background-color: ${props.theme.colors.blue};
            background-image: url(${props.bgImage});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          `
        : css`
            background: ${props.theme.colors.darkGradient};
          `};

    box-shadow: ${(props) => props.$layer && 'inset 0 0 0 1000px rgba(0, 0, 0, 0.80)'};
    ${media('medium', true)} {
      height: ${(props) => (props.$type === 'secondary' ? 1000 : 300)}px;
    }
    p {
      color: ${(props) => props.theme.colors.lightText};
      line-height: 1.5rem;
    }
    & > span {
      color: #fff;
      padding: 32px;
      max-width: ${(props) => (props.$small ? '640px' : '1200px')};
      text-align: ${(props) => (props.$small ? 'left' : 'center')};
      position: absolute;
      span {
        color: inherit !important;
      }
    }

    h1 {
      margin: 0;
      color: #fff !important;
      font-size: ${(props) => (!props.$small ? '55px' : '35px')};
      ${media('medium', true)} {
        font-size: 35px;
      }
    }
  }
`;

export const BannerContent = styled(Container)`
  /* Container */
  color: #fff;
  max-width: 1200px !important;
  padding-top: 92px;
  flex-direction: column;
  ${media('medium')} {
    flex-direction: row;
  }
  /* Text container */
  > div:first-of-type {
    width: 100%;
    padding: 16px;
  }
  /* Image */
  > img {
    z-index: -1;
    width: 50%;
    align-self: center;
    border-radius: 10px;
    ${media('medium')} {
      height: 360px;
      display: block;
      position: relative !important;
    }
  }
  > div.image-tertiary {
    z-index: -1;
    margin-top: 75px;
    width: 50%;
    height: 380px;
    ${media('medium', true)} {
      width: 100%;
      margin-top: 0;
    }
    > img {
      width: 100%;
      height: 100%;
      align-self: center;
      border-radius: 10px;
      object-fit: cover;
      ${media('medium')} {
        display: block;
        position: relative !important;
      }
    }
  }
`;

export const BannerContainer = styled.div<{
  inverted?: boolean;
  full?: boolean;
}>`
  width: 100%;
  height: ${(props) => (props.full ? '100%' : '90%')};
  ${media('medium')} {
    height: ${(props) => (props.full ? '100%' : '90%')};
  }
  position: absolute;
  background: ${({ theme }) =>
    css`linear-gradient(45deg, ${theme.colors.darkBlue} 0%, ${theme.colors.blue} 100%)`};
  clip-path: ${(props) =>
    props.inverted
      ? 'polygon(0 0, 100% 9%, 100% 100%, 0 91%)'
      : 'polygon(0 0, 100% 0, 100% 100%, 0 91%)'};
`;

export const TitlePage = styled.div`
  h2 {
    color: ${(props) => props.theme.colors.white};
    font-size: 1.6em;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 50px;
    margin-top: 18px;
  }

  h1 {
    color: ${(props) => props.theme.colors.white};
    margin: 0;
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    margin-bottom: 48px;
  }
`;

export const Content = styled.div<{
  backgroundColor?: string;
  height?: number;
}>`
  position: relative;
  overflow: hidden;
  display: flex;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : props.theme.colors.white};

  min-height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
`;

export const StoryCustomContent = styled.div`
  max-width: 600px;
  text-align: left;
  color: white;
`;
