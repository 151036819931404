import React from 'react';
import { formatSrcSet } from '../../../utils/formatURLs';
import transformUrl from '../../../utils/transformURL';
import Typography from '../Typography';
import { BannerPrimary, BannerContainer, BannerContent, TitlePage, Content } from './styles';

export type BannerTemplateProps = {
  title?: string | React.ReactNode;
  description?: string;
  imageUrl?: string;
  bannerImg?: string;
  size?: 'full' | number;
  layer?: boolean;
  type?: 'primary' | 'secondary' | 'tertiary';
};

type TypeOptions = {
  primary: () => JSX.Element | React.ReactNode;
  secondary: () => JSX.Element | React.ReactNode;
  tertiary: () => JSX.Element | React.ReactNode;
};

/**
 * Banner
 */
const BannerTemplate: React.FC<React.PropsWithChildren<BannerTemplateProps>> = ({
  title,
  description,
  imageUrl,
  bannerImg,
  type = 'primary',
  children,
}) => {
  const img = bannerImg || imageUrl;

  const typeOptions: TypeOptions = {
    primary: () => (
      <BannerPrimary
        bgImage={img ? (transformUrl(img, 'banner') as string) : undefined}
        $small={!!description}
        $layer={false}
      >
        <div>
          <span>
            <Typography variant="h1" fontWeight="500">
              {title || children}
            </Typography>
            {description && (
              <div
                style={{ marginTop: '10px' }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </span>
        </div>
      </BannerPrimary>
    ),
    secondary: () => (
      <BannerPrimary
        bgImage={img ? (transformUrl(img, 'banner') as string) : '/new-breadcrumb.png'}
        size="full"
        $type="secondary"
        $small={!!description}
        $layer={true}
      >
        <div>
          <span>
            <Typography variant="h1" fontWeight="500">
              {title || children}
            </Typography>
            {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
          </span>
        </div>
      </BannerPrimary>
    ),
    tertiary: () => (
      <Content height={625}>
        <BannerContainer />
        <BannerContent>
          <TitlePage>
            <Typography variant="h1" color="white" fontWeight="500">
              {title || children}
            </Typography>
            {description && <h2 dangerouslySetInnerHTML={{ __html: description }} />}
          </TitlePage>
          {bannerImg && (
            <div className="image-tertiary">
              <img srcSet={formatSrcSet(bannerImg, 'bannerTertiary')} alt="" />
            </div>
          )}
        </BannerContent>
      </Content>
    ),
  };

  return <>{typeOptions[type]()}</>;
};

export default BannerTemplate;
