import styled from 'styled-components';

export const SectionQuoteWrapper = styled.section`
  width: 100%;
  padding: 80px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background: black;
  background: radial-gradient(141.77% 218.28% at -42.79% 9.15%, #dcebff 0%, #ffffff 100%);
`;

export const ContentWrapper = styled.div`
  position: relative;
  width: 1200px;
`;

export const TextWrapper = styled.div`
  position: relative;
  z-index: 2;
  padding: 0px 42px;

  width: 100%;
  font-weight: 600;
  font-size: 32px;
  color: ${(props) => props.theme.colors.darkText};

  a,
  span {
    color: #3888fc;
    font-weight: 600;
  }
`;

export const QuoteImage = styled.div`
  position: absolute;
  top: -46px;
  left: 16px;
  z-index: 0;
`;
