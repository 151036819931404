import styled from 'styled-components';

interface IButtonsWrapperProps {
  buttonsAlignment: 'left' | 'center' | 'right';
}

export const CallToActionWrapper = styled.section`
  width: 100%;
`;

export const Content = styled.div<{ backgroundColor?: string }>`
  max-width: 1200px;
  margin: 0 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 3rem;
    line-height: 4.5rem;
    font-weight: 500;
    letter-spacing: -0.02em;
    padding-bottom: 39px;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 2.125rem;
      line-height: 42px;
    }
  }

  p {
    font-style: normal;
    font-weight: 425;
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin: 0;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) =>
      props.backgroundColor === 'lightGradient'
        ? props.theme.colors.darkText
        : props.theme.colors.white};
  }
`;

export const ButtonsWrapper = styled.div<IButtonsWrapperProps>`
  display: flex;
  align-items: ${(props) => props.buttonsAlignment};
  justify-content: ${(props) => props.buttonsAlignment};
  width: 100%;

  margin-top: 54px;

  div {
    display: flex;
    gap: 16px;
    text-decoration: none;
  }

  a {
    text-decoration: none;
  }
`;
