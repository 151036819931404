import React from 'react';

import Lightbox from 'react-image-lightbox';

type ModalImageProps = {
  handleIsOpen: (index: number) => void;
  handleOnMovePrevRequest?: () => void;
  handleOnMoveNextRequest?: () => void;
  index?: number;
  mainSrc: string;
  nextSrc?: string | undefined;
  prevSrc?: string | undefined;
};

const ModalImageTemplate: React.FC<ModalImageProps> = ({
  index,
  mainSrc,
  handleIsOpen,
  prevSrc,
  nextSrc,
  handleOnMoveNextRequest,
  handleOnMovePrevRequest,
}) => {
  return (
    <Lightbox
      {...{ mainSrc, prevSrc, nextSrc }}
      onCloseRequest={() => handleIsOpen(index as number)}
      onMoveNextRequest={handleOnMoveNextRequest}
      onMovePrevRequest={handleOnMovePrevRequest}
    />
  );
};

export default ModalImageTemplate;
