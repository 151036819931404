import { Blog } from '../../../types/schema';
import PostItem from '../PostItem';
import Typography from '../Typography';
import { BlogPostListWrapper } from './styles';

type BlogPostListProps = {
  post?: Blog;
  title?: string;
  limit?: number;
  lastBlogs?: Blog[];
};

const BlogPostList: React.FC<BlogPostListProps> = ({ post, title, lastBlogs }) => {
  return (
    <BlogPostListWrapper>
      {title && (
        <div className="bogPostList--title">
          <Typography variant="h2" fontSize="45px">
            {title}
          </Typography>
        </div>
      )}
      {post && <PostItem post={post} isEn={post.locale === 'en'} />}
      {!post && lastBlogs && (
        <ul>
          {lastBlogs.map((postItem, index) => (
            <PostItem key={index} post={postItem} isEn={postItem.locale === 'en'} />
          ))}
        </ul>
      )}
    </BlogPostListWrapper>
  );
};
export default BlogPostList;
