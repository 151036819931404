/* eslint-disable camelcase */
import { useRouter } from 'next/router';
import { ComponentComponentsEventList, Event } from '../../../types/schema';
import * as S from './styles';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import ClientComponent from '../ClientComponent/ClientComponent';
import Button from '../Button';
import Link from 'next/link';
import color from 'color';
import Image from 'next/image';

export type EventListProps = ComponentComponentsEventList & { events: Event[]; locale?: string };

enum EventLocation {
  NoBrasil = 'No_Brasil',
  ForaDoBrasil = 'Fora_do_Brasil',
}

enum EventTiming {
  Future = 'Future',
  Past = 'Past',
}

function EventListComponent({ sort, year, events, ...props }: EventListProps) {
  const router = useRouter();
  const location = props.location as unknown as EventLocation | undefined;
  const timing = props.timing as unknown as EventTiming | undefined;
  const isEn = props.locale ? props.locale === 'en' : router.locale === 'en';

  const eventsData = events.reduce((acc, event) => {
    // Filter by year
    if (year && new Date(event.startDate).getFullYear() !== Number(year)) {
      return acc;
    }

    // Filter by location
    if (location) {
      const isBrasil = ['Brasil', 'Brazil', 'brasil', 'brazil'].includes(event.country || '');
      if (location === EventLocation.NoBrasil && !isBrasil) {
        return acc;
      }
      if (location === EventLocation.ForaDoBrasil && isBrasil) {
        return acc;
      }
    }

    // Filter by timing
    if (timing) {
      const isFuture = new Date(event.startDate).getTime() > new Date().getTime();
      if (timing === EventTiming.Future && !isFuture) {
        return acc;
      }
      if (timing === EventTiming.Past && isFuture) {
        return acc;
      }
    }

    return [...acc, event];
  }, [] as Event[]);

  const sortedEvents = eventsData.sort((a, b) => {
    const aDate = new Date(a.startDate);
    const bDate = new Date(b.startDate);

    if (sort === 'Asc') return aDate.getTime() - bDate.getTime();
    if (sort === 'Desc') return bDate.getTime() - aDate.getTime();
    return 0;
  });

  const eventsByMonth = sortedEvents.reduce(
    (acc, event) => {
      let month = new Date(event.startDate).toLocaleDateString(isEn ? 'en-US' : 'pt-BR', {
        month: 'long',
      });

      month = month.charAt(0).toUpperCase() + month.slice(1);

      if (!acc[month]) {
        acc[month] = [];
      }

      acc[month].push(event);

      return acc;
    },
    {} as Record<string, Event[]>,
  );

  return (
    <S.Container>
      {Object.entries(eventsByMonth).map(([month, events]) => (
        <S.SectionContainer key={month} scale={timing === EventTiming.Past ? 0.75 : 1}>
          <h3 className="month">{month}</h3>
          <div className="events">
            {events.map((event, index) => (
              <EventItem
                key={index}
                event={event}
                showCountry={location === EventLocation.ForaDoBrasil || isEn}
                isEn={isEn}
                isPast={timing === EventTiming.Past}
              />
            ))}
          </div>
        </S.SectionContainer>
      ))}
    </S.Container>
  );
}

function EventItem({
  event,
  showCountry,
  isEn,
  isPast,
}: {
  event: Event;
  showCountry: boolean;
  isEn: boolean;
  isPast: boolean;
}) {
  const [expanded, setExpanded] = useState(
    !!event.detailPageUrl || !!event.detailPageMessage || false,
  );
  const [enableExpand, setEnableExpand] = useState(false);
  const [isLocationLarger, setIsLocationLarger] = useState(false);

  const descriptionRef = useRef<HTMLParagraphElement | null>(null);
  const locationContainerRef = useRef<HTMLDivElement | null>(null);

  const toggleExpand = () => setExpanded(!expanded);

  const isBrasil = ['Brasil', 'Brazil', 'brasil', 'brazil'].includes(event.country || '');

  const imageUrl = event.image?.data?.attributes?.url;

  const isSameName =
    event.state && event.city && event.state.toLowerCase() === event.city.toLowerCase();

  const formattedUrl = event.url
    ? `${event.url}${event.url.includes('?') ? '&' : '?'}utm_source=AppMasters.io`
    : '';

  const description = event.description ? event.description.replace(/\n\n/g, '<br>') : '';

  useEffect(() => {
    const updateStates = () => {
      if (!event.detailPageMessage && !event.detailPageUrl) {
        const descriptionHeight = descriptionRef.current?.scrollHeight || 0;
        setEnableExpand(
          (descriptionHeight > 173 && !isPast) || (descriptionHeight > 116 && isPast),
        );
      }

      const locationHeight = locationContainerRef.current?.scrollHeight || 0;
      setIsLocationLarger(locationHeight > 100);
    };

    updateStates();
    window.addEventListener('resize', updateStates);

    return () => window.removeEventListener('resize', updateStates);
  }, [descriptionRef.current, locationContainerRef.current, isPast]);

  return (
    <S.EventItem
      scale={isPast ? 0.75 : 1}
      expanded={expanded}
      isLocationLarger={isLocationLarger}
      style={event.backgroundColor ? { backgroundColor: event.backgroundColor } : {}}
    >
      {imageUrl && (
        <div className="img-container">
          <Image
            src={imageUrl}
            alt=""
            width={500}
            height={500}
            placeholder="blur"
            blurDataURL="data:image/webp;base64,UklGRgACAABXRUJQVlA4WAoAAAAgAAAAiAAAiAAASUNDUMgBAAAAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADZWUDhMEQAAAC+IACIAB9DqVre6/4GI6H8AAA=="
          />
        </div>
      )}

      <div className="info-container">
        <div className="header">
          <div className="location-container" ref={locationContainerRef}>
            <h3 className="title">{event.title}</h3>
            <p className="location">
              {isSameName ? (
                <span className="city">
                  {event.city}
                  {!isEn && isBrasil ? ' (capital)' : ' (city)'}
                </span>
              ) : (
                <span className="city">
                  {event.city}
                  {event.state && event.city && ', '}
                  {event.state}
                </span>
              )}
              {showCountry && event.country && (
                <span>{event.country === 'Brasil' && isEn ? 'Brazil' : event.country}</span>
              )}
            </p>
          </div>

          <ClientComponent>
            <span className="date">
              {new Date(event.startDate).toLocaleDateString(isEn ? 'en-US' : 'pt-BR', {
                day: 'numeric',
                month: 'long',
              })}
            </span>
          </ClientComponent>
        </div>

        {event.description && (
          // <p className="description" dangerouslySetInnerHTML={{ __html: description }}></p>
          <p
            ref={descriptionRef}
            className="description"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
        {(event.detailPageMessage || event.detailPageUrl) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 8,
            }}
          >
            {event.detailPageMessage && (
              <p className="detail-page-message">{event.detailPageMessage}</p>
            )}
            {event.detailPageUrl && (
              <Link
                href={event.detailPageUrl}
                target="_blank"
                style={{ border: 'none', float: 'right', flexShrink: 0 }}
              >
                <Button
                  variant="primary"
                  style={{
                    padding: '10px 15px',
                    ...(event.backgroundColor && {
                      background: 'unset',
                      backgroundColor: color(event.backgroundColor as string)
                        .darken(0.4)
                        .hex(),
                    }),
                  }}
                >
                  Ver mais
                </Button>
              </Link>
            )}
          </div>
        )}

        <div
          className="btn-container"
          style={{ flexDirection: enableExpand ? 'row-reverse' : 'row' }}
        >
          {enableExpand && (
            <button onClick={toggleExpand} className="read-more">
              {isEn
                ? expanded
                  ? 'Show less'
                  : 'Show more'
                : expanded
                  ? 'Mostrar menos'
                  : 'Mostrar mais'}
              {expanded ? <BiChevronUp size={24} /> : <BiChevronDown size={24} />}
            </button>
          )}

          {event.url && (
            <a href={formattedUrl} target="_blank" rel="noreferrer">
              Visitar Site <FaExternalLinkAlt size={12} />
            </a>
          )}
        </div>
      </div>
    </S.EventItem>
  );
}

export default function EventList(props: EventListProps) {
  return <EventListComponent {...props} />;
}
