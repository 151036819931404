import { GalleryWrapper } from './styles';

import ModalImage from '../../elements/ModalImage';
import { useState } from 'react';
import { Carousel } from '../Carousel';

export type GalleryProps = {
  columns?: number;
  images: string[];
  cloudinaryOriginalURLs: string[];
  galleryImagePosition?: 'covered' | 'contained';
  size?: 'small' | 'medium' | 'large';
  width?: number;
  height?: number;
  isCarousel?: boolean;
  carouselBackground?: 'primary' | 'white' | 'transparent';
  carouselWidth?: 'page' | 'content';
  richTextId?: string;
};

const Gallery: React.FC<GalleryProps> = ({
  images,
  columns = 2,
  size = 'large',
  width = 1000,
  height = 700,
  galleryImagePosition = 'covered',
  cloudinaryOriginalURLs,
  isCarousel = false,
  carouselBackground = 'transparent',
  carouselWidth = 'content',
  richTextId,
}) => {
  const imagePosition = galleryImagePosition;

  const [isOpen, setIsOpen] = useState(false);

  const [photoIndex, setPhotoIndex] = useState<number>(0);
  function handleIsOpen() {
    setIsOpen(!isOpen);
  }

  if (isCarousel)
    return (
      <Carousel
        backgroundColor={carouselBackground}
        width={carouselWidth}
        imgUrls={cloudinaryOriginalURLs.map((url) => ({ src: url }))}
      />
    );

  return (
    <>
      <GalleryWrapper style={richTextId ? { margin: '0 auto' } : {}} columns={columns} size={size}>
        {images.map((image, i) => (
          <div
            key={`${image}-${i}`}
            className={`img ${imagePosition || 'covered'}`}
            style={{ aspectRatio: (width / height).toString() }}
            onClick={() => {
              handleIsOpen();
              setPhotoIndex(i);
            }}
          >
            <img
              className="border-radius"
              key={`gallery-image-${i}`}
              alt=""
              srcSet={image}
              sizes="(max-width: 991px) 576px,
              (max-width: 1219px) 992px,
              1220px"
              loading="lazy"
            />
          </div>
        ))}
      </GalleryWrapper>
      <ModalImage
        image={cloudinaryOriginalURLs[photoIndex]}
        isOpen={isOpen}
        handleIsOpen={handleIsOpen}
      />
    </>
  );
};

export default Gallery;
