import React from 'react';
import { TechnologyWrapper } from './styles';
import { Technology, UploadFile } from '../../../types/schema';
import { formatSrcSet } from '../../../utils/formatURLs';

type TechnologyItemProps = { columnNumber?: number; technology: Technology };

const TechnologyItem: React.FC<TechnologyItemProps> = ({ columnNumber, technology }, i) => {
  const { logo, title, locale } = technology;

  return (
    <TechnologyWrapper
      key={i}
      passHref
      href={
        locale === 'en'
          ? `/en/technologies/${technology.slug}/`
          : `/pt/tecnologias/${technology.slug}/`
      }
      columnNumber={columnNumber}
      title={
        locale === 'en' ? 'Link to view technology ' : 'Link para visualizar tecnologia ' + title
      }
      tabIndex={0}
    >
      <div>
        {logo && (
          <div className="logo">
            <img
              srcSet={formatSrcSet(logo.data?.attributes as UploadFile, 'technology')}
              alt=""
              aria-hidden
              loading="lazy"
              width="70px"
            />
          </div>
        )}
        <h3>{title}</h3>
      </div>
    </TechnologyWrapper>
  );
};

export default TechnologyItem;
