import React from 'react';
import HTMLParsedContent from '../HTMLParsedContent';
import { Wrapper } from './styles';

export type SectionBackgroundImageRichTextProps = {
  textPosition: 'left' | 'center' | 'right';
  text: string;
  backgroundColor?: string;
  backgroundImage?: string;
};

const SectionBackgroundImageRichText: React.FC<SectionBackgroundImageRichTextProps> = ({
  textPosition,
  text,
}) => {
  return (
    <Wrapper textPosition={textPosition}>
      <HTMLParsedContent content={text}>teste</HTMLParsedContent>
    </Wrapper>
  );
};

export default SectionBackgroundImageRichText;
