import { useMemo } from 'react';
import { formatSrcSet } from '../../../utils/formatURLs';
import HTMLParsedContent from '../HTMLParsedContent';
import { Wrapper, TextContent } from './styles';
import { getRegex } from '../../../utils/object';

export type RichtextProps = {
  body: string;
  background: 'primary' | 'transparent' | 'white';
  textColor?: string;
  titleColor?: string;
  default: boolean;
  image?: string;
};

const Richtext: React.FC<RichtextProps> = (props) => {
  const body = useMemo(() => {
    const regexVideo = getRegex(['Video']);

    const newBody = props.body.split(regexVideo);
    const iframeIndex = newBody.map((b, i) => (b.includes('Video https://') ? i - 1 : null));

    if (newBody && newBody.length > 0) {
      return newBody.map((body: string, index: number) => {
        if (body.includes('Video https://')) {
          const videoUrl = body.slice(0, -1).split('Video ')[1];
          const iframe = (
            <iframe
              key={videoUrl}
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          );
          return iframe;
        } else {
          return (
            <TextContent key={body} className={props.image ? 'text' : ''}>
              <HTMLParsedContent content={iframeIndex.includes(index) ? body.slice(0, -1) : body} />
            </TextContent>
          );
        }
      });
    }
    return [
      <TextContent key={props.body} className={props.image ? 'text' : ''}>
        <HTMLParsedContent key={props.body} content={props.body} />
      </TextContent>,
    ];
  }, [props.body]);

  return (
    <Wrapper
      background={props.background}
      default={props.default}
      textColor={props.textColor}
      titleColor={props.titleColor}
      length={props.body.length}
    >
      {props.image && (
        <div className="image">
          <img
            srcSet={formatSrcSet(props.image, 'gallery')}
            alt="Imagem que acompanha o texto"
            loading="lazy"
          />
        </div>
      )}
      {body.map((b) => b)}
    </Wrapper>
  );
};

export default Richtext;
