import styled from 'styled-components';
import media from '../media';
import { Quicksand } from 'next/font/google';

const quicksand = Quicksand({
  subsets: ['latin'],
  display: 'swap',
});

export const lineHeight = 1.8;

export const Container = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  width: 100%;
  height: inherit;
  margin: auto;
  font-family: ${quicksand.style.fontFamily};
  max-width: 85%;
  ${media('large', 'extraLarge')} {
    max-width: 950px;
  }
  ${media('extraLarge')} {
    max-width: 1200px;
  }
`;

export const SectionContainer = styled(Container).attrs({
  as: 'section',
})``;

export const BaseContainer = styled(SectionContainer)`
  margin-top: 0;
  h1 {
    font-weight: 400;
    font-size: 32px;
    ${media('medium')} {
      font-size: 40px;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
  }
  p {
    color: ${(props) => props.theme.colors.darkTextPale};
    line-height: ${lineHeight}em;
  }
`;
