import styled from 'styled-components';
import media from '../../../styles/media';

interface AccordionItemProps {
  clicked: number | null;
  index: number;
}

interface SectionTitleItemListWrapperProps {
  contentOrientation: 'column' | 'row';
}

interface ListProps {
  listType: 'accordion' | 'numeric';
  contentOrientation: 'column' | 'row';
}

export const SectionWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SectionTitleItemListWrapper = styled.div<SectionTitleItemListWrapperProps>`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    color: ${(props) => props.theme.colors.darkText};
    margin: 0;
  }

  .rich-text h1 {
    font-size: 96px;
    line-height: 112px;
    letter-spacing: -0.02em;
  }
  .rich-text h2 {
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
  }
  .rich-text h3 {
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.02em;
  }
  .rich-text h4 {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -0.02em;
  }
  .rich-text h5 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
  }
  .rich-text h6 {
    font-size: 20px;
    line-height: 32px;
  }

  .rich-text p {
    font-weight: 425;
    font-size: 16px;
    line-height: 26px;
  }

  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: ${(props) => (props.contentOrientation === 'column' ? 'column' : 'row')};
  justify-content: ${(props) => (props.contentOrientation === 'column' ? 'center' : 'initial')};
  align-items: ${(props) => (props.contentOrientation === 'column' ? 'center' : 'initial')};
  gap: 64px;

  .rich-text {
    margin-bottom: ${(props) => (props.contentOrientation === 'column' ? '108px' : '0px')};
    width: ${(props) => (props.contentOrientation === 'column' ? '80%' : '40%')};
    text-align: ${(props) => (props.contentOrientation === 'column' ? 'center' : 'initial')};
    p {
      font-weight: 425;
    }
  }

  ${media('medium', true)} {
    flex-direction: column;
    align-items: center;

    .rich-text {
      width: 100%;
      text-align: center;
      margin-bottom: 64px;
      padding: 0;
    }
  }

  ${media('extraLarge', true)} {
    padding: 0px;
  }
`;

export const List = styled.ul<ListProps>`
  list-style: none;
  width: ${(props) => (props.contentOrientation === 'column' ? '80%' : '60%')};
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.listType === 'accordion' ? '10px' : '0px!important')};

  .text {
    color: ${(props) => props.theme.colors.darkText};
    font-weight: 425;
    font-size: 16px;
    line-height: 26px;
  }

  ${media('medium', true)} {
    width: 100%;
    padding: 0;
    li {
      padding: 0;
    }
  }
`;

export const AccordionItem = styled.li<AccordionItemProps>`
  .panel {
    overflow: hidden;
    transition: 0.5s ease;
    p {
      padding-top: 0;
      padding: 10px 20px;
    }
  }

  .arrow-icon {
    transition: 0.5s ease;
    rotate: ${(props) => (props.clicked === props.index ? '0deg' : '90deg')};
    margin-left: 10px;
    flex-shrink: 0;
  }
`;

export const AccordionButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 50px;

  border: 0;
  border-radius: 10px 10px 0 0;
  outline: 0;
  background: #ffffff;

  padding: 10px 20px;

  p {
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: 14px;

    svg {
      flex-shrink: 0;
    }

    ${media('extraSmallest', true)} {
      gap: 6px;
      svg {
        /* display: none; */
      }
    }
  }

  ${media('extraSmallest', true)} {
    padding: 6px 6px;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const AccordionPanel = styled.div`
  transition: 0.5s ease;
  width: 100%;
  height: 0;
  padding: 0 20px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 0 0 10px 10px;

  p {
    font-weight: 425;
    font-size: 14px;
    line-height: 26px;
    padding-bottom: 10px;
  }
`;

export const NumericItem = styled.li`
  display: flex;
  gap: 14px;

  h3 {
    margin: 0;
    padding: 0;
    font-weight: 700px;
    font-size: 1rem;
  }

  p {
    font-size: 14px;
    font-weight: 425px;
  }

  .content {
    margin-bottom: 24px;
    text-align: initial;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${(props) => props.theme.colors.darkText};
      padding: 0;
      margin: 0;
    }

    h3.title {
      font-weight: 700;
      font-size: 16px;
      line-height: 27px;
    }
    p {
      font-weight: 425;
      font-size: 14px;
      line-height: 27px;
    }
  }
`;

export const OrderCount = styled.div`
  display: flex;
  flex-direction: column;
  width: 58px;
  .numeric-circle {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background: ${(props) => props.theme.colors.lightGrayBlue};

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      padding: 0;

      font-weight: 700;
      font-size: 23px;
      color: ${(props) => props.theme.colors.primary};
    }
  }

  .vertical-line {
    min-width: 2px;
    width: 2px;
    min-height: calc(24px);
    margin: 0 auto;
    height: calc(100% - 58px);
    background: ${(props) => props.theme.colors.lightGrayBlue};
  }
`;
