import styled from 'styled-components';
import { SectionContainer } from '../../../styles/Container';
import media from '../../../styles/media';

export const Wrapper = styled.div<{
  background?: 'primary' | 'transparent' | 'white';
}>``;

export const Text = styled.div<{
  textAlign: 'left' | 'center' | 'right';
}>`
  text-align: ${(props) => props.textAlign};
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`;

export const TechnologyWrapper = styled(SectionContainer)<{
  techsInsideContainer?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  ${media('medium')} {
    max-width: ${(props) => (props.techsInsideContainer ? '1200px' : '85%')};
  }
  > * {
    h3 {
      font-weight: 400;
    }

    &:hover {
      > * {
        > *:first-child:not(.image) {
          box-shadow: 1px 1px 10px 3px rgba(255, 255, 255, 0.5) !important;
        }
      }
    }
    :focus-visible {
      outline: 2px solid black; //${(props) => props.theme.colors.darkGray};
      outline-offset: 2px;
      border-radius: 2px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
