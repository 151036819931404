import styled, { css } from 'styled-components';
import { Theme } from '../../../types/styled-components';

type CardBackground = 'white' | 'darkGradient' | 'lightGradient' | 'darkBlue';

export const UserTestimonyItemWrapper = styled.div<{
  $compact?: boolean;
  $cardBackground?: CardBackground;
}>`
  max-width: 1200px;
  display: flex;
  ${(props) =>
    props.$compact
      ? css`
          margin: 0 auto;
        `
      : ''}
  flex-direction: column !important;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;

  // Background color
  background: ${(props) => getBackgroundColor(props.theme, props.$cardBackground)};
  *, // Content
  p, // User info
  h3 {
    color: ${(props) =>
      ['darkBlue', 'darkGradient'].includes(props.$cardBackground || '')
        ? props.theme.colors.white
        : props.theme.colors.darkText} !important;
  }
`;

export const UserContainer = styled.div<{ $compact?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${(props) => (props.$compact ? '5px' : '10px')};
`;

export const UserInfo = styled.div`
  h3 {
    padding: 0;
    margin: 0;
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  p {
    padding: 0;
    margin: 0;
    font-size: 14px !important;
    font-weight: 300 !important;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;

const flagSize = 15; // Pixels
export const FlagIcon = styled.div`
  width: ${flagSize}px;
  height: ${flagSize}px;

  img {
    width: ${flagSize}px;
    height: ${flagSize}px;
    border-radius: 5px;
  }
`;

export const UserPicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
`;

const getBackgroundColor = (theme: Theme, cardBackground?: CardBackground) => {
  switch (cardBackground) {
    case 'darkGradient':
      return theme.colors.darkGradient;
    case 'lightGradient':
      return theme.colors.lightGradient;
    case 'darkBlue':
      return theme.colors.darkBlue;
    default:
      return theme.colors.white;
  }
};
