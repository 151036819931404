/* eslint-disable camelcase */
import { Container, UserTestimonyWrapper } from './styles';
import Typography from '../Typography';
import {
  Enum_Componentcomponentstestimonialdetail_Clientcountry,
  Enum_Testimony_Type,
  Testimony,
  UploadFile,
} from '../../../types/schema';
import UserTestimonyItem from '../UserTestimonyItem';

export type UserTestimonyProps = {
  id?: string;
  title?: string;
  subtitle?: string;
  compact?: boolean;
  testimonies: Testimony[];
  testimoniesType?: Enum_Testimony_Type;
  isTestimonySelected?: boolean;
  limit?: number;
  backgroundColor?: string;
  columns?: number; // Default is 1
  cardBackground?: 'white' | 'darkGradient' | 'lightGradient' | 'darkBlue';
};

/**
 * UserTestimony
 */
const UserTestimony: React.FC<UserTestimonyProps> = ({
  title,
  subtitle,
  compact,
  testimonies,
  testimoniesType,
  limit,
  isTestimonySelected,
  columns,
  cardBackground,
  backgroundColor,
}) => {
  const isType = testimonies[0].type;
  const [...filteredTestimonies] =
    isType === undefined || isTestimonySelected || !testimoniesType
      ? testimonies
      : testimonies.filter((testimony) => testimony.type === testimoniesType).slice(0, limit);

  return (
    <Container backgroundColor={backgroundColor || ''}>
      {title && ( // Keeping title and subtitle for now, so we don't break any older view, but it's better to use the container title
        <Typography variant="h2" fontSize="45px" style={!subtitle ? { marginBottom: 22 } : {}}>
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography variant="h3" color="darkText">
          {subtitle}
        </Typography>
      )}
      <UserTestimonyWrapper columns={columns || 1}>
        {filteredTestimonies.map((testimony, i) => (
          <UserTestimonyItem
            key={i}
            compact={compact}
            name={testimony.name as string}
            image={testimony.image as UploadFile}
            companyName={testimony.companyName as string}
            occupation={testimony.occupation as string}
            clientCountry={
              testimony.clientCountry as unknown as Enum_Componentcomponentstestimonialdetail_Clientcountry
            }
            testimony={testimony.testimony as string}
            cardBackground={cardBackground}
          />
        ))}
      </UserTestimonyWrapper>
    </Container>
  );
};

export default UserTestimony;
