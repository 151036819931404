import styled, { css } from 'styled-components';
import media from '../../../styles/media';

export const PostWrapper = styled.li<{ related?: boolean }>`
  overflow: hidden;
  border-radius: ${(props) => props.theme.border.borderRadius};
  border: ${(props) => props.theme.border.borderDark};
  width: 100%;
  max-width: 1200px;
  margin: auto;
  list-style: none;
  ${(props) =>
    props.related
      ? css`
          ${media('large', false)} {
            margin-top: 16px;
          }
          ${media('large')} {
            max-width: calc(33% - 16px);
            margin: 8px;
          }
          img {
            width: 372px;
            height: 124px;
            object-fit: cover;
          }
        `
      : css``}
  > section {
    width: 100%;
    padding-top: 33.25%;
    min-height: 50%;
    overflow: hidden;
    position: relative;
  }
  img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  /* Margin para elementos a partir do segundo */
  + li {
    ${(props) => (props.related ? `` : 'margin-top: 36px;')}
  }
  /* Segundo div do item => conteúdo de texto */
  > div {
    &:nth-of-type(1) {
      position: relative;
      padding: 14px 24px;
      /* Link */
      a {
        /* Título */
        :hover {
          text-decoration: none !important;
          h2 {
            opacity: 0.9;
            /* color: ${(props) => props.theme.colors.blue}; */
            text-decoration: none !important;
          }
        }
        :focus-visible {
          h2 {
            outline: 2px solid ${(props) => props.theme.colors.darkText};
            outline-offset: 3px;
            border-radius: 3px;
          }
        }
        h2 {
          color: ${(props) => props.theme.colors.darkText};
          font-weight: 500;
          margin: 0;
          font-size: 20px;
          ${media('medium')} {
            font-size: 20px;
          }
        }
        /* Autor e data */
        span {
          color: ${(props) => props.theme.colors.mutedText};
          font-size: 0.8em;
        }
      }
      /* Texto */
      div > p > span {
        color: ${(props) => props.theme.colors.darkTextPale} !important;
        font-weight: 400 !important;
        font-size: 0.95em !important;
        margin-bottom: 0;
      }
    }
  }
`;
