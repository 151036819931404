import styled, { css } from 'styled-components';

export const CarouselContainer = styled.div<{
  width: 'content' | 'page';
  backgroundColor: 'primary' | 'white' | 'transparent';
}>`
  margin: auto;
  width: 100%;
  max-width: ${(props) => (props.width === 'content' ? '1200px' : '100vw')};
  background-color: ${(props) =>
    props.backgroundColor === 'transparent'
      ? 'transparent'
      : props.backgroundColor === 'primary'
        ? props.theme.colors.blue
        : props.theme.colors.white};
  padding: 1rem 0 3.5rem;

  .slick-prev {
    left: 8px !important;
    z-index: 1;
  }

  .slick-next {
    right: 8px !important;
    z-index: 1;
  }

  .slick-slide {
    transition: transform 0.2s ease-in;
    &:not(.slick-center.slick-current) {
      transform: scale(0.9);
    }
  }

  .slick-dots {
    bottom: -50px;
  }
`;

export const CarouselItem = styled.div<{ url: string }>`
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 4px 8px #0004;
  margin: auto;
  height: 270px;
  width: 100%;

  background-image: url(${(props) => props.url});
  background-position: center;
  background-size: cover;
`;

export const CarouselDots = styled.ul<{ carouselColor?: 'white' | 'primary' | 'transparent' }>`
  padding-bottom: 1rem;

  li {
    margin: 0 8px;
    &.slick-active {
      button {
        outline: 2px solid
          ${(props) =>
            props.carouselColor === 'primary' ? props.theme.colors.white : props.theme.colors.blue};
        outline-offset: 2px;
        opacity: 1;
      }
    }

    button {
      width: 25px;
      height: 25px;
      font-size: 12px;
      color: ${(props) =>
        props.carouselColor === 'primary' ? props.theme.colors.blue : props.theme.colors.white};
      background-color: ${(props) =>
        props.carouselColor === 'primary' ? props.theme.colors.white : props.theme.colors.blue};

      border-radius: 50%;
      opacity: 0.7;
      transition:
        opacity 0.2s ease,
        outline 0.2s ease;

      &:hover,
      &:focus-visible {
        opacity: 1;
      }

      &::before {
        content: '';
      }
    }
  }
`;

export const CarouselArrow = styled.button<{ carouselColor?: 'white' | 'primary' | 'transparent' }>`
  font-size: 24px;
  padding: 2px 12px;
  opacity: 1;
  transition: opacity 0.2s ease-in;
  border-radius: 16px;
  width: auto;
  height: auto;

  ${(props) =>
    props.carouselColor === 'primary'
      ? css`
          background-color: ${(props) => props.theme.colors.darkBlue}80;
          border: 2px solid ${(props) => props.theme.colors.white};
          color: ${(props) => props.theme.colors.white};

          &:hover,
          &:active,
          &:focus {
            background-color: ${(props) => props.theme.colors.darkBlue}80;
            border: 2px solid ${(props) => props.theme.colors.white};
            color: ${(props) => props.theme.colors.white};
            opacity: 1;
          }
        `
      : css`
          background-color: ${(props) => props.theme.colors.white}80;
          border: 2px solid ${(props) => props.theme.colors.blue};
          color: ${(props) => props.theme.colors.blue};

          &:hover,
          &:active,
          &:focus {
            background-color: ${(props) => props.theme.colors.white}80;
            border: 2px solid ${(props) => props.theme.colors.blue};
            color: ${(props) => props.theme.colors.blue};
            opacity: 1;
          }
        `}

  &::before {
    content: '' !important;
  }
`;
