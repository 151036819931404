import React from 'react';
import Button, { ButtonVariant } from '../Button';
import { TechnologyWrapper, Text, Wrapper, ButtonWrapper } from './styles';
import TechnologyItem from '../../elements/TechnologyItem';
import Typography from '../Typography';
import { Technology } from '../../../types/schema';
import Stackshare from '../Stackshare';
import Link from 'next/link';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'transparent'
  | 'outline'
  | 'default';

export type TechnologyGridProps = {
  compact?: boolean;
  numberOfColumns?: number;
  technologies: Technology[];
  title?: string;
  description?: string;
  background?: 'primary' | 'transparent' | 'white';
  textAlign: 'left' | 'center' | 'right';
  featured?: boolean;
  buttonType?: ButtonType;
  buttonTitle?: string;
  buttonId?: string;
  buttonAction?: string;
  techsInsideContainer?: boolean;
  stackshareUrl?: string;
  locale?: string;
};

const order = {
  high: 1,
  medium: 2,
  low: 3,
  null: 4,
};

const TechnologyGrid: React.FC<TechnologyGridProps> = ({
  compact,
  numberOfColumns,
  technologies,
  title,
  description,
  background,
  textAlign,
  featured,
  buttonId,
  buttonTitle,
  buttonType,
  buttonAction,
  techsInsideContainer,
  stackshareUrl,
  locale,
}) => {
  const isEn = locale === 'en';
  const isFeatured = technologies[0]?.featured;
  const [...techs] =
    isFeatured === undefined || !featured
      ? technologies
      : technologies.filter((tech) => tech.featured);

  return (
    <Wrapper background={background}>
      <Text style={{ marginBottom: '40px' }} textAlign={textAlign}>
        <Typography marginBottom="0" variant="h2" fontSize="48px" color="white">
          {title || (isEn ? 'Technologies' : 'Tecnologias')}
        </Typography>
        {description && (
          <Typography variant="p" color="lightText" fontSize="18px">
            {description}
          </Typography>
        )}
      </Text>

      <TechnologyWrapper techsInsideContainer={techsInsideContainer}>
        {techs &&
          techs
            .sort((a, b) => {
              return order[a.priority || 'null'] - order[b.priority || 'null'];
            })
            .map((technology) => (
              <TechnologyItem
                columnNumber={numberOfColumns ? numberOfColumns : compact ? 6 : undefined}
                key={technology.title as string}
                technology={technology}
              />
            ))}
      </TechnologyWrapper>
      {stackshareUrl && <Stackshare url={stackshareUrl} />}
      {buttonType && buttonTitle && buttonAction?.includes('https://') && (
        <ButtonWrapper>
          <Link href={buttonAction} target="_blank">
            <Button
              key={buttonId || buttonTitle}
              variant={(buttonType || 'default') as ButtonVariant}
            >
              {buttonTitle}
            </Button>
          </Link>
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default TechnologyGrid;
