import styled from 'styled-components';

export const ContainerStackshare = styled.div`
  display: flex;
  height: auto;
  justify-content: center;
  margin-bottom: 60px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  a .logo-container {
    background: #0690fa;
    height: ${Math.round(30 * Math.sqrt(2) * 1.1)}px;
    width: ${Math.round(30 * Math.sqrt(2) * 1.1)}px;
    box-shadow: 1px 1px 4px 0px #ccc;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      color: white;
      height: 35px;
      width: 35px;
    }
  }
  a span {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.1;
  }

  a:hover {
    text-decoration: none;
  }
`;
