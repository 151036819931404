/* eslint-disable camelcase */
import React from 'react';
import { formatSrcSet } from '../../../utils/formatURLs';

import HTMLParsedContent from '../HTMLParsedContent';
import { Wrapper } from './styles';

export type ImageFit = 'absolutBottom' | 'absolutFull' | 'centerAndFit' | 'centerAndCover';
export type ImageProportion = 'oneThird' | 'half' | 'twoThirds';

export type SectionImageRichTextProps = {
  text: string;
  image: string;
  imageFit: ImageFit;
  imageProportion: ImageProportion;
  backgroundColor: string;
};

const SectionImageRichText: React.FC<SectionImageRichTextProps> = ({
  text,
  image,
  imageFit,
  imageProportion,
  backgroundColor,
}) => {
  return (
    <Wrapper
      imageFit={imageFit}
      imageProportion={imageProportion}
      backgroundColor={backgroundColor}
    >
      <div className="img-container">
        <img
          alt=""
          srcSet={formatSrcSet(image, undefined, {
            quality: 60,
          })}
          loading="lazy"
        />
      </div>
      <HTMLParsedContent content={text} />
    </Wrapper>
  );
};

export default SectionImageRichText;
