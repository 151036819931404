import styled, { css } from 'styled-components';
import media from '../../../styles/media';
import { Enum_Componentcomponentssectioncards_Cardsposition as CardsPosition } from '../../../types/schema';

export const Container = styled.section<{
  backgroundColor?: 'blue' | 'darkBlue';
  cardsCount: number;
  cardsPosition: CardsPosition;
}>`
  display: flex;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ? theme.colors[backgroundColor] : 'transparent'};
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  justify-content: center;

  ${(props) =>
    props.cardsPosition === 'inline'
      ? css`
          flex-direction: column;
          align-items: center;
        `
      : css`
          flex-direction: ${props.cardsPosition === 'right' ? 'row' : 'row-reverse'};
          gap: 16px;
        `}

  div.text-content {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div {
      width: 100%;
    }

    * {
      color: ${(props) => props.theme.colors.darkText};
    }

    ${(props) =>
      props.backgroundColor &&
      css`
        * {
          color: ${(props) => props.theme.colors.white};
        }
      `}

    h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: ${({ cardsPosition }) => (cardsPosition === 'inline' ? 'center' : 'left')};
    }

    p {
      text-align: left;
    }

    h1,
    h2 {
      font-size: 45px;
      font-weight: 500;
      line-height: 49.5px;
    }
  }

  ol.cards-container {
    display: grid;
    list-style: none;
    padding: 16px;
    gap: 16px;
    width: 100%;
    justify-content: space-evenly;

    ${(props) =>
      props.cardsPosition === 'inline'
        ? css`
            grid-template-columns: repeat(${props.cardsCount}, minmax(250px, 400px));
          `
        : css`
            grid-template-columns: repeat(2, minmax(250px, 400px));
            grid-template-rows: repeat(2, minmax(250px, 1fr));
          `}
    li {
      border-radius: 12px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      min-height: 250px;

      ${(props) =>
        props.backgroundColor
          ? css`
              background-color: ${props.theme.colors.white};
            `
          : css`
              box-shadow: 0 0 4px ${props.theme.colors.cardBlue}cc;
            `}

      .icon {
        height: 24px;
        width: 24px;
        margin-bottom: 1rem;
        object-fit: contain;
      }

      h3 {
        display: flex;
        flex-direction: column;
        color: ${(props) => props.theme.colors.blue};
        margin-bottom: 0;
        font-size: 18px;
        span {
          font-size: 24px;
          color: ${(props) => props.theme.colors.cardBlue};
        }
      }

      p {
        font-size: 14px;
      }

      hr {
        height: 1px;
        width: 100%;
        border: none;
        background-color: ${(props) => props.theme.colors.cardBlue};
      }

      :has(img.icon) {
        h3 {
          span {
            font-size: 18px;
            &::after {
              content: '.';
            }
          }
        }
      }
    }
  }

  a {
    font-weight: 600;
    ${(props) =>
      props.backgroundColor &&
      css`
        color: ${props.theme.colors.white};
      `}
    display: flex;
    width: fit-content;
    gap: 8px;
    align-items: center;
    padding: 8px;
    text-decoration: none;
    border-bottom: 2px solid ${(props) => props.theme.colors.cardBlue};

    &:hover {
      text-decoration: none;
    }

    ${(props) =>
      props.cardsPosition === 'inline'
        ? css`
            margin-top: 16px;
          `
        : css`
            margin-top: auto;
          `}
  }

  ${media('medium', true)} {
    flex-direction: column;
    align-items: center;
    div.text-content {
      padding: 0;
    }

    ol.cards-container {
      grid-template-columns: repeat(2, minmax(250px, 1fr));
      padding: 0;
      gap: 16px;
      li {
        min-height: 250px;
      }
    }
  }
  ${media('extraSmall', true)} {
    ol.cards-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  ${(props) =>
    props.cardsPosition === 'inline' &&
    css`
      ol.cards-container {
        @media (max-width: 1050px) {
          grid-template-columns: repeat(3, minmax(250px, 1fr));
        }

        @media (max-width: 790px) {
          grid-template-columns: repeat(2, minmax(250px, 1fr));
        }

        @media (max-width: 524px) {
          grid-template-columns: repeat(1, minmax(250px, 1fr));
        }
      }
    `}
  ol.cards-container {
    @media (max-width: 524px) {
      grid-template-columns: repeat(1, minmax(250px, 1fr));
    }
  }
`;
