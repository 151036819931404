import React from 'react';
import Link from 'next/link';
import { CgArrowLongRight } from 'react-icons/cg';
import { FaCode } from 'react-icons/fa';

import {
  ComponentBackground,
  ItemContent,
  ItemIcon,
  ListItem,
  ItemNavigation,
  ListWrapper,
} from './styles';

export type Content = {
  content: string;
  icon?: string; // Custom icon url
  linkText?: string; // Button label
  linkDestination?: string; // Url
};

type Props = {
  content: Content[];
  backgroundColor?: 'darkGradient' | 'darkBlue'; // Used only by the container
};

const SectionTextColumns: React.FC<Props> = (props) => {
  React.useEffect(() => {
    // Checking for extra content, since we can't limit it on the Strapi
    if (props.content.length > 4) {
      console.error(`Invalid content length. Maximum is 4, but received ${props.content.length}`);
    }
  }, [props.content.length]);

  return (
    <ComponentBackground>
      <ListWrapper itemCount={props.content.length}>
        {props.content.slice(0, 4).map((item, index) => (
          <ListItem key={index}>
            <ItemIcon>
              {item.icon ? <img src={item.icon} alt="icon" /> : <FaCode size={20} />}
            </ItemIcon>
            <div>
              <ItemContent dangerouslySetInnerHTML={{ __html: item.content }} />
              {item.linkDestination ? (
                <ItemNavigation>
                  <Link href={item.linkDestination}>
                    {item.linkText || 'Learn more'} <CgArrowLongRight />
                  </Link>
                </ItemNavigation>
              ) : null}
            </div>
          </ListItem>
        ))}
      </ListWrapper>
    </ComponentBackground>
  );
};

export default SectionTextColumns;
