import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  default: boolean;
  titleColor?: string;
  textColor?: string;
  background: 'primary' | 'transparent' | 'white';
  length: number;
}>`
  iframe {
    margin: auto;
    width: 500px;
    aspect-ratio: 16 / 9;
    display: block;
    flex: 1;
  }

  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) =>
    props.background === 'transparent' ? 'transparent' : props.theme.colors[props.background]};
  ${(props) =>
    !props.default &&
    css`
      h1 {
        font-size: 48px;
        line-height: 1.1;
        margin-bottom: 50px;
      }
      h2 {
        font-size: 36px;
        line-height: 1.2;
      }
      h3 {
        font-size: 28px;
        line-height: 1.1;
      }
      h4 {
        font-size: 22px;
        line-height: 1.1;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-top: 0px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.darkText};
      }

      p,
      li {
        color: rgb(116, 115, 115);
        font-size: 18px;
        font-weight: 400;
      }
    `}
  ${(props) =>
    props.length > 2020
      ? css`
          .img {
            float: left;
            width: 500px;
            height: 600px;
            margin-right: 20px;
          }
          .text {
            width: 100%;
          }
        `
      : css`
          display: flex;
          justify-content: center;
          align-items: center;
          /* grid-template-columns: calc(100% - 700px) 500px; */
          gap: 20px;

          .image {
            float: left;
            width: 500px;
            height: 600px;
            padding-top: 50%;
            margin-right: 20px;
            overflow: hidden;
            position: relative;
          }
          .image img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
          }
          .text {
            width: 700px;
          }
        `}
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    .image {
      padding-top: 50%;
      height: 100%;
      width: calc(100% - 200px);
      margin-right: 0;
    }
    .text {
      width: calc(100% - 50px);
    }
  }

  @media screen and (max-width: 650px) {
    .image {
      float: none;
      margin-right: 0;
      padding-bottom: 0;
    }
    .image img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
    }

    iframe {
      width: 100%;
    }
  }
`;

export const TextContent = styled.div`
  font-size: 18px;
  width: 100%;

  ul {
    padding-left: 40px;
  }
  ol {
    padding-left: 40px;
  }
  a {
    text-decoration: underline;
    color: rgb(43, 41, 41);
    font-weight: 400;
  }
  li {
    margin: 8px 0;
  }

  blockquote {
    background-image: url('/img/quote-mark-white.svg'),
      radial-gradient(141.77% 218.28% at -42.79% 9.15%, #dcebff 0%, #ffffff 100%);
    background-repeat: no-repeat, no-repeat;
    background-size: 52px, cover;
    background-position-x: 8px, 0;
    background-position-y: 16px, 0;

    border: 0;
    max-width: 1200px;
    width: 100%;

    font-size: 20px;

    p {
      margin: 0;
    }
  }
`;

export const CKEditorWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 1rem; */
  width: 100%;
  ul {
    padding-left: 1rem;
    li {
      &::marker {
        position: absolute;
        content: '\f00c'; /* FontAwesome Unicode */
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
      }
    }
  }
  /*
* CKEditor 5 (v28.0.0) content styles.
* Generated on Mon, 07 Jun 2021 06:33:15 GMT.
* For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
*/
  :root {
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-table-caption-background: hsl(0, 0%, 97%);
    --ck-color-table-caption-text: hsl(0, 0%, 20%);
    --ck-highlight-marker-blue: hsl(201, 97%, 72%);
    --ck-highlight-marker-green: hsl(120, 93%, 68%);
    --ck-highlight-marker-pink: hsl(345, 96%, 73%);
    --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
    --ck-highlight-pen-green: hsl(112, 100%, 27%);
    --ck-highlight-pen-red: hsl(0, 85%, 49%);
    --ck-image-style-spacing: 1.5em;
    --ck-todo-list-checkmark-size: 16px;
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .marker-green {
    background-color: var(--ck-highlight-marker-green);
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
  }
  /* ckeditor5-highlight/theme/highlight.css */
  .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
  }
  /* ckeditor5-font/theme/fontsize.css */
  .text-tiny {
    font-size: 0.7em;
  }
  /* ckeditor5-font/theme/fontsize.css */
  .text-small {
    font-size: 0.85em;
  }
  /* ckeditor5-font/theme/fontsize.css */
  .text-big {
    font-size: 1.4em;
  }
  /* ckeditor5-font/theme/fontsize.css */
  .text-huge {
    font-size: 1.8em;
  }
  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }
  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }
  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }
  /* ckeditor5-image/theme/imagestyle.css */
  .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }
  /* ckeditor5-image/theme/image.css */
  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 1em;
    padding: 0 1rem;
  }
  /* ckeditor5-image/theme/image.css */
  .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 50px;
  }
  /* ckeditor5-image/theme/imagecaption.css */
  .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: hsl(0, 0%, 20%);
    background-color: hsl(0, 0%, 97%);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
  }
  /* ckeditor5-image/theme/imageresize.css */
  .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
  }
  /* ckeditor5-image/theme/imageresize.css */
  .image.image_resized img {
    width: 100%;
  }
  /* ckeditor5-image/theme/imageresize.css */
  .image.image_resized > figcaption {
    display: block;
  }
  /* ckeditor5-language/theme/language.css */
  span[lang] {
    font-style: italic;
  }
  /* ckeditor5-code-block/theme/codeblock.css */
  pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
  }
  /* ckeditor5-code-block/theme/codeblock.css */
  pre code {
    background: unset;
    padding: 0;
    border-radius: 0;
  }
  /* ckeditor5-horizontal-line/theme/horizontalline.css */
  hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
  }
  /* ckeditor5-html-embed/theme/htmlembed.css */
  .raw-html-embed {
    margin: 1em auto;
    min-width: 15em;
    font-style: normal;
  }
  /* ckeditor5-block-quote/theme/blockquote.css */
  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
  }
  /* ckeditor5-block-quote/theme/blockquote.css */
  [dir='rtl'] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
  }
  /* ckeditor5-basic-styles/theme/code.css */
  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: 0.15em;
    border-radius: 2px;
  }
  /* ckeditor5-table/theme/table.css */
  .table {
    margin: 1em auto;
    display: table;
  }
  /* ckeditor5-table/theme/table.css */
  .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
  }
  /* ckeditor5-table/theme/table.css */
  .table table td,
  .table table th {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid hsl(0, 0%, 75%);
  }
  /* ckeditor5-table/theme/table.css */
  .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
  }
  /* ckeditor5-table/theme/table.css */
  [dir='rtl'] .table th {
    text-align: right;
  }
  /* ckeditor5-table/theme/table.css */
  [dir='ltr'] .table th {
    text-align: left;
  }
  /* ckeditor5-table/theme/tablecaption.css */
  .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: 0.6em;
    font-size: 0.75em;
    outline-offset: -1px;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .page-break::after {
    content: '';
    position: absolute;
    border-bottom: 2px dashed hsl(0, 0%, 77%);
    width: 100%;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .page-break__label {
    position: relative;
    z-index: 1;
    padding: 0.3em 0.6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  /* ckeditor5-media-embed/theme/mediaembed.css */
  .media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em;
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list {
    list-style: none;
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list li {
    margin-bottom: 5px;
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list li .todo-list {
    margin-top: 5px;
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition:
      250ms ease-in-out box-shadow,
      250ms ease-in-out background,
      250ms ease-in-out border;
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
      calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label > input[checked]::after {
    border-color: hsl(0, 0%, 100%);
  }
  /* ckeditor5-list/theme/todolist.css */
  .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
  }
  /* ckeditor5-mention/theme/mention.css */
  .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
  }
  @media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .page-break {
      padding: 0;
    }
    /* ckeditor5-page-break/theme/pagebreak.css */
    .page-break::after {
      display: none;
    }
  }
`;

export const DefaultText = styled.div<{
  default: boolean;
  titleColor?: string;
  textColor?: string;
  listColor?: string;
  background: 'primary' | 'transparent' | 'white';
}>`
  background-color: ${(props) =>
    props.background === 'transparent' ? 'transparent' : props.theme.colors[props.background]};
  h1,
  h2,
  h3,
  h4 {
    color: ${(props) => (!props.default ? props.titleColor : 'black')};
    margin-top: 0px;
    font-weight: 500;
  }
  h1 {
    font-size: 45px;
    line-height: 1.1;
    margin-bottom: 50px;
  }
  h2 {
    font-weight: bold;
    line-height: 1.2;
  }
  h4 {
    font-size: 18px;
    line-height: 1.1;
  }

  ul {
    color: ${(props) => (!props.default ? props.listColor : 'rgb(127, 127, 127)')};
  }
  p {
    color: ${(props) => (!props.default ? props.textColor : 'rgb(127, 127, 127)')};
    margin: 0px auto 28px;
    line-height: 2;
    font-size: 15px;
    font-weight: 400;
    a {
      color: ${(props) => (!props.default ? props.textColor : 'rgb(127, 127, 127)')};
    }
  }
`;
