import styled from 'styled-components';
import media from '../../../styles/media';
import Link from 'next/link';

export const TechnologyWrapper = styled(Link)<{ columnNumber?: number }>`
  width: calc(50% - 20px);
  margin: auto;
  margin-bottom: 60px;
  text-align: center;
  min-width: 115px;
  cursor: pointer;
  ${media('medium')} {
    width: ${(props) =>
      props.columnNumber ? `calc(${100 / props.columnNumber}% - 20px)` : 'calc(25% - 20px)'};
  }
  &:hover {
    text-decoration: underline;
    > div > div:first-child {
      transform: translateY(-5px);
      box-shadow: 1px 1px 5px 0px #aaa;
    }
  }
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    > div:first-child {
      background: white;
      height: ${Math.round(70 * Math.sqrt(2) * 1.1)}px;
      width: ${Math.round(70 * Math.sqrt(2) * 1.1)}px;
      transition: all 0.1s ease-in-out;
      overflow: visible;
      box-shadow: 1px 1px 4px 0px #ccc;
      border-radius: 100%;
      padding: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
      }
    }
  }
`;
