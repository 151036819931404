import { FC, useEffect, useMemo, useState } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import Lightbox from 'react-image-lightbox';
import { CarouselArrow, CarouselContainer, CarouselDots, CarouselItem } from './styles';
import Slider, { Settings } from 'react-slick';

type CarouselImg = { key?: string | number; alt?: string; src: string };
type ZoomImg = { index: number; url: string };

type CarouselProps = {
  imgUrls: CarouselImg[];
  backgroundColor: 'white' | 'primary' | 'transparent';
  width: 'page' | 'content';
};

export const Carousel: FC<CarouselProps> = ({ imgUrls, backgroundColor, width = 'content' }) => {
  const [zoom, setZoom] = useState({
    current: {} as ZoomImg,
    prev: {} as ZoomImg,
    next: {} as ZoomImg,
  });
  const [dragging, setDragging] = useState(false);
  const [zoomOpen, setZoomOpen] = useState(false);
  const [slidesToShow, setSlidesToShow] = useState(1);

  const setZoomImg = (index: number) => {
    if (!zoomOpen) setZoomOpen(true);
    const prevIndex = index === 0 ? imgUrls.length - 1 : index - 1;
    const nextIndex = index === imgUrls.length - 1 ? 0 : index + 1;

    const currentItem = { index, url: imgUrls[index].src };
    const prevItem = { index: prevIndex, url: imgUrls[prevIndex].src };
    const nextItem = { index: nextIndex, url: imgUrls[nextIndex].src };
    setZoom({ current: currentItem, prev: prevItem, next: nextItem });
  };

  const settings: Settings = useMemo(
    () => ({
      // Core
      accessibility: false,
      arrows: true,
      dots: true,
      centerMode: slidesToShow > imgUrls.length,
      centerPadding: '48px',
      infinite: true,
      // Pagination and buttons
      prevArrow: (
        <CarouselArrow carouselColor={backgroundColor} type="button" aria-hidden title="previous">
          <BsArrowLeft />
        </CarouselArrow>
      ),
      nextArrow: (
        <CarouselArrow carouselColor={backgroundColor} type="button" aria-hidden title="next">
          <BsArrowRight />
        </CarouselArrow>
      ),
      appendDots: (dots) => (
        <div>
          <CarouselDots carouselColor={backgroundColor} aria-hidden>
            {dots}
          </CarouselDots>
        </div>
      ),
      customPaging: (index) => {
        return <button type="button">{index + 1}</button>;
      },
      // Responsive
      slidesToShow:
        slidesToShow > imgUrls.length
          ? imgUrls.length
          : width === 'content' && slidesToShow > 3
            ? 3
            : slidesToShow,
      slidesToScroll: 1,
      touchMove: true,
      swipeToSlide: true,
      beforeChange: () => setDragging(true),
      afterChange: () => setDragging(false),
    }),
    [slidesToShow, imgUrls.length, width, backgroundColor],
  );

  useEffect(() => {
    const listener = () => {
      const { innerWidth } = window;
      if (innerWidth <= 480) setSlidesToShow(1);
      else if (innerWidth <= 768) setSlidesToShow(2);
      else if (innerWidth <= 1366) setSlidesToShow(3);
      else setSlidesToShow(Math.floor(innerWidth / 480));
    };
    window.addEventListener('resize', listener);
    listener();

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  const ZoomContainer = () => {
    const { current, prev, next } = zoom;

    return zoomOpen ? (
      <Lightbox
        onCloseRequest={() => setZoomOpen(false)}
        mainSrc={current.url}
        prevSrc={prev.url}
        nextSrc={next.url}
        prevSrcThumbnail={prev.url}
        nextSrcThumbnail={next.url}
        animationDisabled={false}
        onMovePrevRequest={() => setZoomImg(prev.index)}
        onMoveNextRequest={() => setZoomImg(next.index)}
      />
    ) : (
      <></>
    );
  };

  return (
    <CarouselContainer {...{ width, backgroundColor }}>
      <Slider {...settings}>
        {imgUrls.map(({ key, src }, index) => (
          <CarouselItem
            url={src}
            key={key || index}
            onClick={(e) => {
              if (dragging) {
                e.preventDefault();
                e.stopPropagation();
              } else setZoomImg(index);
            }}
          />
        ))}
      </Slider>
      <ZoomContainer />
    </CarouselContainer>
  );
};
