import React, { useState, useRef, useEffect } from 'react';
import HTMLParsedContent from '../HTMLParsedContent';
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  List,
  NumericItem,
  OrderCount,
  SectionTitleItemListWrapper,
  SectionWrapper,
} from './styles';

import CheckMarkIcon from '../../../styles/svgs/check-mark.svg';
import ArrowRightIcon from '../../../styles/svgs/arrow-right.svg';
import { useWindowSize } from 'react-use';

export type SectionTitleItemListProps = {
  contentOrientation: 'column' | 'row';
  backgroundColor: 'lightGradient' | 'white';
  listType: 'accordion' | 'numeric';
  text: string;
  items: ListItems[];
};

interface ListItems {
  title: string;
  text: string;
}

const SectionTitleItemList: React.FC<SectionTitleItemListProps> = ({
  contentOrientation,
  listType,
  text,
  items,
}) => {
  const [clicked, setClicked] = useState<number | null>(0);
  const [accordionHeight, setAccordionHeight] = useState<number | undefined>(0);
  const listRef = useRef<HTMLUListElement | null>(null);

  const { width } = useWindowSize();

  const handleToggleAccordion = (index: number) => {
    if (clicked === index) {
      return setClicked(null);
    }
    setClicked(index);
  };

  useEffect(() => {
    setAccordionHeight(listRef.current?.children[0].children[1].children[0].scrollHeight);
  }, []);

  useEffect(() => {
    if (clicked === null) return;

    setAccordionHeight(listRef.current?.children[clicked].children[1].children[0].scrollHeight);
  }, [clicked, width]);

  return (
    <SectionWrapper>
      <SectionTitleItemListWrapper contentOrientation={contentOrientation}>
        <HTMLParsedContent content={text} className="rich-text text" />
        {listType === 'accordion' && (
          <List
            className="list"
            listType={listType}
            contentOrientation={contentOrientation}
            ref={listRef}
          >
            {items.map((item, index) => (
              <AccordionItem key={item.title} clicked={clicked} index={index}>
                <AccordionButton onClick={() => handleToggleAccordion(index)}>
                  <div className="title-wrapper">
                    <CheckMarkIcon />
                    <p style={{ fontWeight: 700 }}>{item.title}</p>
                  </div>

                  <ArrowRightIcon className="arrow-icon" />
                </AccordionButton>
                <AccordionPanel
                  style={
                    clicked === index
                      ? {
                          height: accordionHeight,
                        }
                      : { height: 0 }
                  }
                >
                  {item.text ? (
                    <HTMLParsedContent className="text" content={item.text} />
                  ) : (
                    <div style={{ height: 0 }}></div>
                  )}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </List>
        )}
        {listType === 'numeric' && (
          <List listType={listType} contentOrientation={contentOrientation}>
            {items.map((item, index) => (
              <NumericItem key={item.title}>
                <OrderCount>
                  <div className="numeric-circle">
                    <p>{index + 1}</p>
                  </div>
                  {index + 1 !== items.length && <div className="vertical-line"></div>}
                </OrderCount>
                <div className="content">
                  <h3 className="title">{item.title}</h3>
                  <HTMLParsedContent className="text" content={item.text} />
                </div>
              </NumericItem>
            ))}
          </List>
        )}
      </SectionTitleItemListWrapper>
    </SectionWrapper>
  );
};

export default SectionTitleItemList;
