/* eslint-disable camelcase */
import styled, { css } from 'styled-components';
import { ImageFit, ImageProportion } from '.';
import media from '../../../styles/media';

export const Wrapper = styled.div<{
  imageFit: ImageFit;
  imageProportion: ImageProportion;
  backgroundColor: string;
}>`
  max-width: 1200px;
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: ${(props) =>
    props.imageProportion === 'half'
      ? '1fr 1fr'
      : props.imageProportion === 'oneThird'
        ? '1fr 2fr'
        : '2fr 1fr'};
  gap: 40px;
  align-items: center;

  ${(props) =>
    props.imageFit === 'absolutBottom' &&
    css`
      ${media('large')} {
        & > div {
          transform: translateY(64px);
        }
      }

      ${media('large', true)} {
        padding-top: 64px;
      }
    `};

  /* On absolutFull, DZ styles does not include padding so we must add it on mobile */
  ${(props) =>
    props.imageFit === 'absolutFull' &&
    css`
      ${media('large', true)} {
        padding: 64px 0px;
      }
    `};

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${(props) =>
      props.backgroundColor === 'lightGradient'
        ? props.theme.colors.darkText
        : props.theme.colors.white};
  }

  ${media('large', true)} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  div.img-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
      props.imageFit !== 'centerAndFit' &&
      css`
        height: 100%;
      `}

    img {
      width: 100%;
      margin: auto;

      ${media('large', true)} {
        position: static;
        max-width: 700px;
      }

      ${(props) =>
        props.imageFit === 'centerAndCover' &&
        css`
          object-fit: cover;
          max-width: 100%;
          max-height: 100%;
          min-width: 100%;
          min-height: 100%;
        `}
    }
  }
`;
