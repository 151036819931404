import parse, { HTMLReactParserOptions } from 'html-react-parser';
import DOMPurify, { Config } from 'isomorphic-dompurify';
import { HTMLParsedContentWrapper } from './styles';

export type HTMLParsedContentProps = React.HTMLAttributes<HTMLDivElement> & {
  /**
   * The content that should be parsed
   */
  content?: string;
  /**
   * The options that are passed down to the parser
   */
  parserOptions?: HTMLReactParserOptions;
  /**
   * The options that are passed down to the sanitizer
   */
  sanitizerOptions?: Config;
};

/**
 * A component that sanitizes then parses and styles a plain rich text/html content
 *
 * @param props The component props
 * @param props.content The content that should be parsed
 * @param props.parserOptions The options that are passed down to the parser
 * @param props.sanitizerOptions The options that are passed down to the sanitizer
 */
const HTMLParsedContent: React.FC<HTMLParsedContentProps> = ({
  content,
  parserOptions,
  sanitizerOptions,
  ...props
}) => {
  return content ? (
    <HTMLParsedContentWrapper {...props}>
      {parse(
        (sanitizerOptions
          ? DOMPurify.sanitize(content as string, sanitizerOptions)
          : DOMPurify.sanitize(content as string)) as string,
        parserOptions,
      )}
    </HTMLParsedContentWrapper>
  ) : null;
};

export default HTMLParsedContent;
