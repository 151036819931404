import { CSSProperties } from 'react';
import { DynamicZoneType } from '../components/elements/DynamicZones';
import * as colors from '../styles/colors';
import { ComponentComponentsSectionImageRichText, UploadFileEntityResponse } from '../types/schema';
import transformUrl from './transformURL';

const isFullWidthComponent = (type: string) => {
  return (
    type === 'ComponentComponentsSectionImageRichText' ||
    type === 'ComponentComponentsSectionBackgroundImageRichText' ||
    type === 'ComponentComponentsSectionQuote' ||
    type === 'ComponentComponentsSectionTextButtons' ||
    type === 'ComponentComponentsCompanyForm' ||
    type === 'ComponentComponentsContactForm' ||
    type === 'ComponentComponentsHubspotMeeting'
  );
};

export const getDZContainerStyles = ({
  page,
  component,
}: {
  page?: string;
  component: DynamicZoneType;
}) => {
  const type = component.__typename;

  switch (page) {
    case 'blog':
      if (isFullWidthComponent(type as string))
        return { paddingTop: 64, paddingBottom: 64 } as CSSProperties;
      break;
    default:
      if (type === 'ComponentComponentsSectionImageRichText') {
        if ((component as ComponentComponentsSectionImageRichText)?.imageFit === 'absolutFull')
          return { paddingTop: 0, paddingBottom: 0 };
        if ((component as ComponentComponentsSectionImageRichText)?.imageFit === 'absolutBottom')
          return { paddingTop: 0 };
      }
      return {};
  }
};

export const getBackgroundColor = (component: DynamicZoneType) => {
  const backgroundColor = (component as unknown as { backgroundColor?: string })?.backgroundColor;
  // Old components use background
  const background = (component as unknown as { background?: string })?.background;

  if (backgroundColor) return colors[backgroundColor as keyof typeof colors] || colors.background;

  return background === 'primary'
    ? colors.blue
    : background === 'white'
      ? colors.white
      : 'transparent';
};

export const getBackgroundImage = (component: DynamicZoneType) => {
  const data = (component as { backgroundImage?: UploadFileEntityResponse })?.backgroundImage;
  return !!(data && data.data)
    ? transformUrl(data.data.attributes?.url as string, '', { quality: 70 })
    : undefined;
};

export const getTextColor = (component: DynamicZoneType) => {
  const backgroundColor = (component as unknown as { backgroundColor?: string })?.backgroundColor;
  const background = (component as unknown as { background?: string })?.background;

  if (backgroundColor)
    switch (backgroundColor) {
      case 'lightGradient':
        return colors.darkText;
      case 'darkGradient':
        return colors.white;
      case 'white':
        return colors.darkText;
      case 'blue':
        return colors.white;
      case 'darkBlue':
        return colors.white;
      default:
        return colors.darkText;
    }

  if (background)
    switch (background) {
      case 'transparent':
        return colors.darkText;
      case 'white':
        return colors.darkText;
      default:
        return colors.white;
    }
};
